import React from 'react'

import {
    Box,
    Center,
    CloseButton,
    Editable,
    EditableInput,
    EditablePreview,
    Flex,
    Heading,
    HStack,
    Stack,
    Tag,
    TagLabel,
    TagLeftIcon,
    TagRightIcon,
    Text,
    useColorModeValue,
} from '@chakra-ui/react'
import {
  AddIcon,
  MinusIcon,
} from '@chakra-ui/icons'

import { baseUrl } from '../env'
import dayjs from '../dayjs'
import CardButtons from './CardButtons'

import { confirmableCardColors as styles } from '../styles'

import { FullTaskOccurrence } from '../types/models'
import { Filter, BinaryFilter, StateUser } from '../types/utility'

interface Style {
  dayColor: string,
  bg: string,
  descriptionColor: string
}

const updateTaskRankings = async function(data: Record<string, number>, occurrence: FullTaskOccurrence, updateOccurrences: () => void ) {
  await fetch(`${baseUrl}/tasks/${occurrence.task.id}`, {
      method: 'PATCH',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data)
  })
}

const rankingInput = (value: number, setValue: StateUser<number>, letter: 'U'|'I'|'D'|'A', update: () => void) => {
  let displayValue
  if (value === 0) {
    displayValue = letter
  } else if (value === 1) {
    displayValue = '🟢'
  } else if (value === 2) {
    displayValue = '🟡'
  } else if (value === 3) {
    displayValue = '🔴'
  } else if (value === 4) {
    displayValue = '❗'
  }
  return (
    <Editable
      size='xs'
      maxWidth={3}
      defaultValue={`${value}`}
      onChange={s => { setValue(+s || 0) }}
      onSubmit={() => update()}
      value={displayValue}
    >
    <EditablePreview />
    <EditableInput/>
  </Editable>
  )
}
 
export default function TaskOccurrenceCard(
  {
    occurrence,
    occurrences,
    setOccurrences,
    updateOccurrences,
    filters,
    setFilters
  }:
  {
    occurrence: FullTaskOccurrence,
    occurrences: FullTaskOccurrence[],
    setOccurrences: StateUser<(FullTaskOccurrence|null)[]>,
    updateOccurrences: () => void,
    filters: Filter[],
    setFilters: StateUser<Filter[]>
  }) {
    const [deletionIsConfirmable, setDeletionIsConfirmable] = React.useState(false)

    const [urgency, setUrgency] = React.useState(occurrence.task.urgency || 0)
    const [importance, setImportance] = React.useState(occurrence.task.importance || 0)
    const [difficulty, setDifficulty] = React.useState(occurrence.task.difficulty || 0)
    const [avoidance, setAvoidance] = React.useState(occurrence.task.avoidance || 0)
    const updateRankings =  () => updateTaskRankings({ urgency, importance, difficulty, avoidance }, occurrence, updateOccurrences)

    const [tagEditMode, setTagEditMode] = React.useState(false)

    const { date, task, resolutionState } = occurrence

    let style : Style
    if (Object.keys(styles).includes(resolutionState.toLowerCase())) {
      // @ts-ignore
      style = styles[resolutionState.toLowerCase()]
    } else {
      style = styles['fallback']
    }

    const allIncentives = [
      ...occurrence.incentives,
      ...occurrence.task.incentives,
      ...occurrence.task.tactics.flatMap(tactic => tactic.incentives)
    ]
    const tacticIncentives = allIncentives.filter(i => ['Tactic', 'TaskTactic', 'TacticUse'].includes(i.type))
    const nonTacticIncentives = allIncentives.filter(i => !['Tactic', 'TaskTactic', 'TacticUse'].includes(i.type))

    const tacticsRow = <HStack>{task.tactics.map(tactic => {
      // Prioritize higher specificity incentives
      const incentiveForTacticUse = tacticIncentives.find(i => i.tacticId === tactic.id && occurrence.id === i.taskOccurrenceId)
        || tacticIncentives.find(i => i.tacticId === tactic.id)
      const incentiveQuantity = incentiveForTacticUse ? `: ${incentiveForTacticUse.quantity}` : ''
      return (
        <Tag key={tactic.id} colorScheme='purple'>
          <TagLabel>{`${tactic.name}${incentiveQuantity}`}</TagLabel>
        </Tag>
      )}
    )}</HStack>


    const getGoalColorScheme = (goalName: string) => {
      const currentFilter = filters.find(f => f.type === 'goal' && f.value === goalName) as BinaryFilter | undefined
      return !currentFilter ? 'blue' : currentFilter.positive ? 'green' : 'red'
    }
    const goalRow = <HStack>{task.goals.map(goal => (
      <Tag key={goal.id} colorScheme={getGoalColorScheme(goal.name)} onClick={e => e.stopPropagation()}>
        <TagLeftIcon boxSize="12px" as={MinusIcon}
          onClick={e => {
            setNegativeFilter('goal', goal.name)
            e.stopPropagation()
          }}
        />
        <TagLabel>{goal.name}</TagLabel>
        <TagRightIcon boxSize="12px" as={AddIcon}
          onClick={e => {
            togglePositiveFilter('goal', goal.name)
            e.stopPropagation()
          }}
        />
      </Tag>
    ))}</HStack>
    const getTagColorScheme = (tagName: string) => {
      const currentFilter = filters.find(f => f.type === 'tag' && f.value === tagName) as BinaryFilter | undefined
      return !currentFilter ? 'gray' : currentFilter.positive ? 'green' : 'red'
    }
    const tagRow = <HStack width='full' onClick={e => { e.stopPropagation(); setTagEditMode(true) }}>
      {task.tags.map(tag => (
      <Tag
        size='sm'
        key={tag}
        colorScheme={getTagColorScheme(tag)}
      >
        <TagLeftIcon boxSize="12px" as={MinusIcon}
          onClick={e => {
            setNegativeFilter('tag', tag)
            e.stopPropagation()
          }}
        />
        <TagLabel>{tag}</TagLabel>
        <TagRightIcon boxSize="12px" as={AddIcon}
          onClick={e => {
            togglePositiveFilter('tag', tag)
            e.stopPropagation()
          }}
        />
      </Tag>
    ))}
    </HStack>
    const setNegativeFilter = (type: 'tag'|'goal', value: string) => {
      setFilters([...filters, { positive: false, type, value }])
    }
    const togglePositiveFilter = (type: 'tag'|'goal', value: string) => {
      const currentFilter = filters.find(f => f.type === type && f.value === value) as BinaryFilter | undefined
      if (currentFilter) {
        setFilters(filters.filter(f => f !== currentFilter))
      } else {
        setFilters([...filters, { positive: true, type, value }])
      }
    }

    return (
      <Center py={3}>
        <Box
          maxW={'445px'}
          w={'full'}
          bg={style.bg}
          boxShadow={'2xl'}
          rounded={'md'}
          p={6}
          overflow={'hidden'}
        >
          <Stack>
            <Flex justifyContent='space-between'>
                <Text
                  color={style.dayColor}
                  textTransform={'uppercase'}
                  fontWeight={800}
                  fontSize={'sm'}
                  letterSpacing={1.1}
                  float='left'
                >
                {date ? dayjs(date).format('dddd') : 'Undated'}
              </Text>
              <Stack
                shouldWrapChildren
                direction='row'
                onClick={e => e.stopPropagation()}
              >
              {rankingInput(urgency, setUrgency, 'U', updateRankings)}
              {rankingInput(importance, setImportance, 'I', updateRankings)}
              {rankingInput(difficulty, setDifficulty, 'D', updateRankings)}
              {rankingInput(avoidance, setAvoidance, 'A', updateRankings)}
              <MinusIcon onClick={() => {
                // Toggle inclusion in collapsedTasks
                const collapsedTasks = JSON.parse(localStorage.getItem('collapsedTasks') || '[]')
                localStorage.setItem('collapsedTasks', JSON.stringify(
                  collapsedTasks.includes(task.id) ?
                  collapsedTasks.filter((t: string) => t !== task.id) :
                  [...collapsedTasks, task.id]
                ))
                setOccurrences([...occurrences, null])
               }}/>
              <CloseButton
                color={deletionIsConfirmable ? 'red' : 'black'}
                onClick={async () => {
                if (deletionIsConfirmable) {
                  await fetch(`${baseUrl}/tasks/${task.id}/occurrences/${occurrence.id}`, {
                    method: 'DELETE'
                  })
                  updateOccurrences()
                } else {
                  setDeletionIsConfirmable(true)
                  setTimeout(() => setDeletionIsConfirmable(false), 2000)
                }
              }}/>
              </Stack>
            </Flex>
            <Heading
              color={useColorModeValue('gray.700', 'white')}
              fontSize={'2xl'}
              fontFamily={'body'}>
              {task.name}
            </Heading>
            <Text color={style.descriptionColor}>
              {/* Silly, but prevents the cards from scrunching if the name and desc are short */}
              {(task.description || '').padEnd(40, String.fromCharCode(8194)).slice(0, 100)}
            </Text>
            { goalRow }
            { tacticsRow }
            { tagEditMode ? null : !task.tags.length ?
              <Text
                color={style.descriptionColor}
                onClick={e => { e.stopPropagation(); setTagEditMode(true) }}
              >
                No tags
              </Text> : tagRow
            }
            {
              !tagEditMode ? null :
              <Editable
                defaultValue={task.tags.join(', ')}
                placeholder='No tags'
                onSubmit={async s => {
                  const tags = s.split(', ').map(t => t.trim()).filter(t => t)
                  task.tags = tags
                  await fetch(`${baseUrl}/tasks/${task.id}`, {
                    method: 'PATCH',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ tags })
                  })
                  updateOccurrences()
                  setTagEditMode(false)
                }}
                onClick={e => e.stopPropagation()}
              >
                <EditablePreview />
                <EditableInput />
              </Editable>
            }
            { occurrence.activeTime && occurrence.activeTime > new Date().toISOString() ?
              <Text color={style.descriptionColor}>
                Active at: {dayjs(occurrence.activeTime).format('MMMM D, h:mm A')}
              </Text> : null
            }
            {
              nonTacticIncentives.length ?
              <HStack>
                {nonTacticIncentives
                .map(incentive => {
                  const typeNameMap = {
                    'TaskStart': 'Start',
                    'TaskContinue': 'Continue',
                    'TaskComplete': 'Complete',
                    'TaskOccurrenceStart': 'Start (TO)',
                    'TaskOccurrenceContinue': 'Continue (TO)',
                    'TaskOccurrenceComplete': 'Complete (TO)'
                  } as const
                  const typeName = typeNameMap[incentive.type as keyof typeof typeNameMap]
                  return (
                    <Tag key={incentive.id} colorScheme='cyan'>
                      <TagLabel>{`${typeName}: ${incentive.quantity}`}</TagLabel>
                    </Tag>
                  )
                })}
              </HStack> : null
            }
            <CardButtons
              modelType='taskOccurrence'
              model={occurrence}
              updateStack={updateOccurrences}
            />
          </Stack>
        </Box>
      </Center>
    )
  }