import { IsoDate, IsoTime } from './utility'

export type GenericTextModel = {
    id: string
    name: string
    description: string | null
    tags: string[]
    createdAt: IsoTime
    deletedAt: IsoTime | null
    updatedAt: IsoTime
}

export type Goal = GenericTextModel
export type LeisureActivity = GenericTextModel
export type Tactic = GenericTextModel & { tags: string[] }

export type BareTaskOccurrence = {
    id: string
    taskId: string
    date: IsoDate | null
    activeTime: IsoTime | null
    resolutionState: 'PENDING'|'STARTED'|'COMPLETED'|'FAILED'|'CANCELLED'
    createdAt: IsoTime
    startedAt: IsoTime | null
    completedAt: IsoTime | null
    failedAt: IsoTime | null
    deletedAt: IsoTime | null
    updatedAt: IsoTime
}

export type BareTask = {
    id: string
    name: string
    description: string | null
    isRecurring: boolean
    schedule: string[]
    period: number | null
    tags: string[]
    urgency: number
    importance: number
    difficulty: number
    avoidance: number
    createdAt: IsoTime
    lastDoneAt: IsoTime | null
    deletedAt: IsoTime | null
    updatedAt: IsoTime
}
export const incentiveTypes = ['TaskStart', 'TaskContinue', 'TaskComplete', 'TaskOccurrenceStart', 'TaskOccurrenceContinue', 'TaskOccurrenceComplete', 'Tactic', 'TaskTactic', 'TacticUse', 'Goal', 'LeisureActivityStart', 'LeisureActivityContinue'] as const
export type IncentiveType = typeof incentiveTypes[number]

export type BareIncentive = {
    id: string
    type: IncentiveType
    quantity: number
    goalId: string | null
    tacticUseId: string | null
    leisureActivityId: string | null
    taskOccurrenceId: string | null
    taskId: string | null
    tacticId: string | null
    deletedAt: null // Should never be fetched if deleted
    updatedAt: IsoTime
}

export type HydratedIncentive = BareIncentive & {
    goal: Goal | null
    tacticUse: Tactic | null
    leisureActivity: LeisureActivity | null
    taskOccurrence: BareTaskOccurrence | null
    task: BareTask | null
    tactic: Tactic | null
}

export type FullTask = BareTask & {
    goals: Goal[]
    incentives: BareIncentive[]
    tactics: (Tactic & { incentives: BareIncentive[] })[]
}

export type TaskOccurrence = BareTaskOccurrence & { task: BareTask }
export type FullTaskOccurrence = TaskOccurrence & {
    task: FullTask,
    incentives: BareIncentive[]
}

export type HistoryEntry = {
    id: string
    type: string | null
    title: string
    details: string | null
    tags: string[],
    instances: number,
    createdAt: IsoTime
    deletedAt: IsoTime | null
    updatedAt: IsoTime
}

export type PointChange = {
    id: string
    quantity: number
    reason: string
    incentive: HydratedIncentive | null
    createdAt: IsoTime
}